import { RemixBrowser } from '@remix-run/react';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HTTPBackend from 'i18next-http-backend';
import { startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { getInitialNamespaces } from 'remix-i18next/client';
import z from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';
import zodEN from 'zod-i18n-map/locales/en/zod.json';
import zodES from 'zod-i18n-map/locales/es/zod.json';
import { i18n } from './utils/i18n.ts';
import zodCustomEN from './zod-locales/en.json';
import zodCustomES from './zod-locales/es.json';

if (ENV.MODE === 'production' && ENV.SENTRY_DSN) {
	import('./utils/monitoring.client.tsx').then(({ init }) => init());
}

async function hydrate() {
	await i18next
		.use(initReactI18next) // Tell i18next to use the react-i18next plugin
		.use(LanguageDetector) // Setup a client-side language detector
		.use(HTTPBackend) // Setup your backend
		.init({
			...i18n, // spread the configuration
			// This function detects the namespaces your routes rendered while SSR use
			ns: getInitialNamespaces(),
			backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
			resources: {
				es: {
					zod: zodES,
					zodCustom: zodCustomES,
				},
				en: {
					zod: zodEN,
					zodCustom: zodCustomEN,
				},
			},
			partialBundledLanguages: true,
			detection: {
				// Here only enable htmlTag detection, we'll detect the language only
				// server-side with remix-i18next, by using the `<html lang>` attribute
				// we can communicate to the client the language detected server-side
				order: ['htmlTag'],
				// Because we only use htmlTag, there's no reason to cache the language
				// on the browser, so we disable it
				caches: [],
			},
		});

	z.setErrorMap(makeZodI18nMap({ t: i18next.t, ns: ['zod', 'zodCustom'] }));

	startTransition(() => {
		hydrateRoot(
			document,
			<I18nextProvider i18n={i18next}>
				<RemixBrowser />
			</I18nextProvider>,
		);
	});
}

if (window.requestIdleCallback) {
	window.requestIdleCallback(hydrate);
} else {
	window.setTimeout(hydrate, 1);
}
